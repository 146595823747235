import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import Sorting from '@zert-packages/components/common/Sorting/Sorting';
import ElementTile from '@zert-packages/components/ElementTile/ElementTile';
import { sortTiles } from '@zert-packages/components/ElementTile/utils/sortTiles';

export function CountedDataDialog({ unmountComponent, counter, countedcataloginfos }) {
  const [sorting, setSorting] = useState('name');
  const [isAscending, setAccedanting] = useState(1);

  const handleConfirm = (info) => () => {
    unmountComponent();
    // onConfirm(info);
  };

  const handleCancel = () => {
    unmountComponent();
    // onCancel();
  };

  const handleSortingChange = (event) => {
    const sorting = event.target.value;
    if (sorting == -1 || sorting == 0 || sorting == 1) {
      setAccedanting(sorting);
    } else {
      setSorting(sorting);
    }
  };

  return (
    <Dialog open onClose={unmountComponent}>
      <DialogTitle>
        <FormattedMessage
          id="CountedDialog.Title"
          defaultMessage="Data in the node {counterName}"
          values={{ counterName: `${counter.label.defaultLabel} - ${countedcataloginfos.length}` }}
        />
      </DialogTitle>
      <DialogContent>
        <Sorting handleSortingChange={handleSortingChange} sorting={sorting} isAscending={isAscending} />
        {countedcataloginfos &&
          sortTiles(countedcataloginfos, sorting, isAscending).map((ra, index) => {
            return <ElementTile hideActions element={ra} show key={index} isNewWindow />;
          })}
      </DialogContent>
      <DialogActions>
        <Button onClick={unmountComponent} color="primary" variant="outlined" autoFocus>
          <FormattedMessage id="CountedDialog.buttonCancel" defaultMessage="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
